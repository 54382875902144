import React from 'react';
import HeaderImageSection from '../header/HeaderImageSection';

const Book_Header = () => {
	return (
		<div className="header-class book">
				<HeaderImageSection id="book-bg" />
                <div className="header-logo">
                </div>
		</div>
	);
};

export default Book_Header;